import {useMutation, useQuery, useQueryClient} from "@tanstack/vue-query";

export function getAddressListMutation() {
    return useMutation(
        ({page = 1, limit = 20} = {}) => {
            return useNuxtApp().$axios("?route=rest/account/address_v2", {
                params: {
                    page,
                    limit
                }
            })
        }
    );
}

export function geocode() {
    return useMutation(
        ({lat, lng}) => {
            lat = typeof lat === 'function' ? lat() : lat
            lng = typeof lng === 'function' ? lng() : lng
            const params = {
                lat: lat,
                lng: lng
            }
            return useNuxtApp().$axios.post("?route=rest/account/googlegeocode", params)
        }
    );
}

export function googlePlaces() {
    return useMutation(
        (params) => {
            return useNuxtApp().$axios.post(`${useNuxtApp().$axios.defaults.baseURL}?route=rest/account/googleplaces`, params)
        }
    );
}

export function addAddress() {
    const queryClient = useQueryClient();
    return useMutation(
        ({id = '', custom_field, firstname, lastname, place = [], telephone, locker = ""}) => {
            const baseUrl = useNuxtApp().$axios.defaults.baseURL;
            const apiUrl = id ? `${baseUrl}?route=rest/account/address_v2&id=${id}` : `${baseUrl}?route=rest/account/address_v2`;

            const requestMethod = id ? useNuxtApp().$axios.put : useNuxtApp().$axios.post;

            const params = {
                custom_field,
                firstname,
                lastname,
                place,
                telephone
            }

            if (locker) {
                params.locker_type = 'redbox'
                params.locker_id = locker.point_id
            }

            return requestMethod(apiUrl, params);
        }
    );
}

export function getAddressDetailsByIdMutation() {
    return useMutation(
        (id) => {
            return useNuxtApp().$axios("?route=rest/account/address_v2", {params: {id}})
        }
    );
}

export function deleteAddressMutation() {
    return useMutation((id = "") => {
            return useNuxtApp().$axios.delete("?route=rest/account/address_v2", {params: {id}})
        }
    )
}

export function getRedBoxLockersMutation() {
    return useMutation(({type = 'redbox', lat = "", lng = "", radius = 2000}) => {
            return useNuxtApp().$axios.delete("?route=rest/v4/parcel_lockers/get_points", {
                params: {
                    type,
                    latitude: lat,
                    longitude: lng,
                    radius
                }
            })
        }
    )
}

export function verify() {
    return useMutation(({address_id = "", code = ""}) => {
        return useNuxtApp().$axios.post("?route=rest/sms/sms/verify", {address_id, code});
    });
}

export function resendVerification() {
    return useMutation(({address_id = "", mobile = ""}) => {
        return useNuxtApp().$axios.post("?route=rest/sms/sms/sendSms", {address_id, mobile});
    });
}